import React, { useEffect } from 'react';

import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

import Login from 'components/Login';
import { getAccessToken } from 'redux/login/auth.helpers';
import { isLoggedInSelector } from 'redux/login/auth.selectors';

export default function LoginPage() {
  const router = useRouter();
  const accessToken = getAccessToken();

  const isUserLoggedIn = useSelector(isLoggedInSelector);

  useEffect(() => {
    if (isUserLoggedIn || accessToken) {
      router.push('/');
    }
  }, [router, accessToken]);

  return <Login />;
}
