import React, { useState } from 'react';

import { Paper, Grid, Button, TextField } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';

import { login } from 'redux/login/auth.actions';
import { isLoginLoadingSelector } from 'redux/login/auth.selectors';

import useStyles from './Login.styles';

const Login = () => {
  const dispatch = useDispatch();

  const classes = useStyles();
  const [userData, setUserData] = useState({ login: '', password: '' });
  const [inputErrors, setErrors] = useState({ login: false, password: false });

  const isLoginLoading = useSelector(isLoginLoadingSelector);

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrors({ login: false, password: false });
    const name = e.currentTarget.name;
    setUserData({ ...userData, [name]: e.target.value });
  };

  const handleLogin = (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!userData.login || !userData.password) {
      setErrors({ login: !Boolean(userData.login), password: !Boolean(userData.password) });
      return;
    }
    dispatch(login({ mobile: userData.login, password: userData.password }));
  };

  return (
    <Paper className={classes.root}>
      <Grid item xs={12} sm={8} md={6} lg={4} xl={3}>
        <Paper elevation={3}>
          <div className={classes.form}>
            <TextField
              className={classes.inputBackground}
              label="Login"
              name="login"
              variant={'outlined'}
              size={'small'}
              type={'text'}
              error={inputErrors.login}
              value={userData.login}
              onChange={changeHandler}
              required
              fullWidth
            />

            <TextField
              variant={'outlined'}
              size={'small'}
              label="Password"
              name="password"
              type={'text'}
              error={inputErrors.password}
              value={userData.password}
              onChange={changeHandler}
              fullWidth
              required
              inputProps={{
                className: 'password-field',
              }}
            />

            <Button fullWidth variant={'contained'} onClick={handleLogin} color={'primary'} disabled={isLoginLoading}>
              Войти
            </Button>
          </div>
        </Paper>
      </Grid>
    </Paper>
  );
};

export default Login;
