import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '10px',
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3),
    },
  },
  inputBackground: {
    backgroundColor: theme.palette.background.paper,
  },
  shadow: {
    boxShadow: '1px -2px 22px -7px ' + theme.palette.secondary.light,
  },
}));

export default useStyles;
